<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> </CCol>
              <CCol md="8" class="text-right">
                <router-link
                  :to="{ path: 'add-jadwal-libur-pelayanan' }"
                  class="btn btn-success btn-sm"
                  ><i class="fa fa-plus"></i> Tambah</router-link
                >
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <table class="table table-sm table-bordered">
                      <thead class="bg-dark text-white">
                        <tr>
                          <th>No</th>
                          <th>Tgl Awal Libur</th>
                          <th>Tgl Akhir Libur</th>
                          <th>Ket</th>
                          <th>Lampiran</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-if="loadingTable">
                            <td colspan="6">
                              <i class="fa fa-spin fa-refresh"></i> Loading...
                            </td>
                          </tr>
                          <tr
                            v-for="(row_data, key_module) in dataintable"
                            v-else
                            :key="key_module"
                          >
                            <td>
                              {{ key_module + 1 }}
                            </td>
                            <td>{{ row_data.tanggal_awal_libur }}</td>
                            <td>{{ row_data.tanggal_akhir_libur }}</td>
                            <td>{{ row_data.catatan_libur }}</td>
                            <td>
                              <div
                                v-if="row_data.lampiran_libur"
                                class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                @click="getFile(row_data.lampiran_libur)"
                              >
                                <i class="fa fa-eye"></i> Lihat
                              </div>
                              <div v-else>
                                <span>Tidak ada file</span>
                              </div>
                            </td>
                            <td>
                              <template>
                                <button
                                  v-if="row_data.status"
                                  class="btn btn-danger btn-sm"
                                  v-on:click="deleteData(row_data.id)"
                                >
                                  <i class="fa fa-trash"></i> Hapus
                                </button>
                                <button
                                  v-else
                                  class="btn btn-warning btn-sm"
                                  v-on:click="restoreData(row_data.id)"
                                >
                                  <i class="fa fa-refresh"></i> Restore
                                </button>
                              </template>
                              <router-link
                                :to="{
                                  name: 'EditJadwalLiburPelayanan',
                                  params: { idJadwal: row_data.id },
                                }"
                                class="btn btn-info btn-sm"
                                ><i class="fa fa-pencil"></i> Edit</router-link
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <nav aria-label="pagination">
                      <ul class="pagination justify-content-start">
                        <li
                          v-for="(page, key_page) in paging"
                          :key="key_page"
                          :class="[
                            'page-item',
                            { active: page.active, disabled: !page.url },
                          ]"
                        >
                          <a
                            href="#"
                            @click.prevent="page.url && toPage(page.url)"
                            class="page-link"
                          >
                            <span v-html="page.label"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
const dataintable = [];
export default {
  name: "AllJadwalPelayanan",
  components: {},
  data() {
    return {
      dataintable: [],
      loadingTable: true,
      paging: [],
      show_data_to: "",
      show_data_from: "",
      total_data: "",
      curent_page: "",
    };
  },
  methods: {
    restoreData(id) {
      // alert(id);
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda akan me-restore data tersebut.",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonClass: `btn btn-warning`,
        confirmButtonText: `Proses`,
        cancelButtonClass: `btn btn-default`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          axios
            .delete(this.apiLink + "api/crud/jadwal-libur-pelayanan", {
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
              data: {
                id: id,
              },
            })
            .then((response) => {
              var res_data = response.data;
              // console.log(res_data);
              if (res_data.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_data.data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Status jadwal pelayanan berhasil diubah.",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    window.location.reload();
                  }
                });
              }
            });
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda akan merubah status jadwal pelayanan",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonClass: `btn btn-danger`,
        confirmButtonText: `Proses`,
        cancelButtonClass: `btn btn-default`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          axios
            .delete(this.apiLink + "api/crud/jadwal-libur-pelayanan", {
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
              data: {
                id: id,
              },
            })
            .then((response) => {
              var res_data = response.data;

              if (res_data.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_data.data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Status jadwal pelayanan berhasil diubah.",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    window.location.reload();
                  }
                });
              }
            });
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },

    getBadge(status) {
      if (status) {
        return "Buka";
      } else {
        return "Libur/Tutup";
      }
    },

    getFile(files) {
      let modifiedUrl = files.replace(/^\//, "");
      var win = window.open(this.apiLink + modifiedUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },

    toPage(url) {
      this.loadingTable = true;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_module = response.data;
          var res_module_data = res_module.data[0];
          console.log("data : ", res_module);
          if (res_module.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_module_data.data.message,
            });
          } else {
            this.loadingTable = false;
            this.dataintable = res_module_data.data;
            this.paging = res_module_data.links;
            this.show_data_from = res_module_data.from;
            this.show_data_to = res_module_data.to;
            this.total_data = res_module_data.total;
            this.curent_page = res_module_data.current_page;
          }
        });
    },
  },
  created() {
    axios
      .get(this.apiLink + "api/crud/jadwal-libur-pelayanan", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_module = response.data;
        var res_module_data = res_module.data[0];
        console.log("data : ", res_module_data.links);
        if (res_module.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_module.data.message,
          });
        } else {
          this.loadingTable = false;
          this.dataintable = res_module_data.data;
          this.paging = res_module_data.links;
          this.show_data_from = res_module_data.from;
          this.show_data_to = res_module_data.to;
          this.total_data = res_module_data.total;
          this.curent_page = res_module_data.current_page;
        }
      });
  },
};
</script>
